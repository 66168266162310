import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { PageDataService } from './page-data.service';
import { Locale } from '../../../translations/models.translations';
import { Breadcrumbs, NewsArticle, OgTags, TwitterTags } from '../models/page-data.type';
import { Subject, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../../../constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private _init$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    @Inject('LOCALE') private locale: Locale,
    private title: Title,
    private meta: Meta,
    private pageDataService: PageDataService,
  ) {
    this._init$.pipe(
      tap(() => {
        this.setDocumentLang(this.locale);
      }),
      switchMap(() => this.processPageData())
    ).subscribe();
  }

  init() {
    this._init$.next();
  }

  private processPageData() {
    return this.pageDataService.pageData$
      .pipe(
        tap((pageData) => {
          if (pageData) {
            this.title.setTitle(`${(pageData.seoTitle || pageData.title)}`);
            this.meta.addTag({ name: 'description', content: pageData.seoDescription || pageData.description });
            this.createCanonicalURL(pageData.canonicalUrl);
            this.createAlternate(pageData.alternateUa, pageData.alternateRu);
            this.createAmpUrl(pageData.ampUrl);
            this.initLdJsonPageData(pageData.breadcrumbs);
            this.initLdJsonPageData(pageData.newsStructuredData);
            this.initOgData(pageData.ogTags);
            this.initTwitterTags(pageData.twitterTags);
          }
        })
      );
  }

  private setDocumentLang(lang: Locale) {
    this.dom.documentElement.lang = lang;
  }

  private createCanonicalURL(url: string) {
    let link = this.dom.querySelector('link[rel="canonical"]');

    if (!link) {
      link = this.dom.createElement('link');
      this.dom.head.appendChild(link);
    }

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
  }

  private createAlternate(urlUa: string | null, urlRu: string | null) {
    if (!urlUa || !urlRu) return;

    const linkUa = this.dom.querySelector(`link[rel="alternate"][hreflang="${Locale.UK}"]`);
    const linkRu = this.dom.querySelector(`link[rel="alternate"][hreflang="${Locale.RU}"]`);

    create(linkUa, Locale.UK, urlUa, this);
    create(linkRu, Locale.RU, urlRu, this);

    function create(link: Element | null, locale: Locale, url: string, _this: SeoService) {
      let linkElement = link;
      if (!linkElement) {
        linkElement = _this.dom.createElement('link');
        _this.dom.head.appendChild(linkElement);
      }
      linkElement.setAttribute('rel', 'alternate');
      linkElement.setAttribute('hreflang', locale);
      linkElement.setAttribute('href', url);
    }
  }

  private initLdJsonPageData(ldJson: Breadcrumbs | NewsArticle | null | undefined) {
    if (!ldJson) return;
    const scriptEl = this.dom.createElement('script');
    scriptEl.type = 'application/ld+json';
    scriptEl.text = `${JSON.stringify(ldJson)}`;
    this.dom.head.appendChild(scriptEl);
  }

  private initOgData(ogData: OgTags | null): void {
    if (!ogData) return;
    Object.keys(ogData).forEach(key => {
      this.meta.updateTag({ property: key, content: ogData[ key ].content });
    });
  }

  private initTwitterTags(twitterData: TwitterTags | null): void {
    if (!twitterData) return;
    Object.keys(twitterData).forEach(key => {
      this.meta.updateTag({ property: key, content: twitterData[ key ].content });
    });
  }

  private createAmpUrl(url: string | null) {
    if(!url) return;

    let link = this.dom.querySelector('link[rel=amphtml]');

    if (!link) {
      link = this.dom.createElement('link');
      this.dom.head.appendChild(link);
    }

    link.setAttribute('rel', 'amphtml');
    link.setAttribute('href', url);
  }
}
