import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlatformDetectorService } from '../platform-detector.service';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private pd: PlatformDetectorService,
    private ngZone: NgZone,
  ) {}

  init() {
    if (this.pd.isServer) {
      this.ngZone.runOutsideAngular(() => {
        const script = this.document.createElement('script');
        script.setAttribute('async', 'true');
        script.setAttribute('fetchpriority', 'low');
        script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MMM4M77X');`;

        this.document.head.appendChild(script);
      });
    }
  }
}
