<div class="menu" [ngClass]="[(menuService.status$ | async) ? 'show' : 'hide']" appDisableScroll [status$]="menuStatus$">
  <div class="lang-panel col-lg-6">
    <app-lang-switcher />
    @if (pd.isDesktopScreen) {
      <app-socials-block/>
    }
  </div>
  @if (menu$ | async; as menus) {
<div class="menu-panel col-lg-6">
    @for (item of menus; track item) {

      <a href="{{item.url}}" target="{{getTarget(item.targetBlank)}}" class="menu-item">{{item.text}}</a>

}
    @if (pd.isMobileScreen) {
      <app-socials-block/>
    }
  </div>
}
</div>
