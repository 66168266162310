import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {PlatformDetectorService} from './platform-detector.service';
import {environment} from '../../../../environments/environment';

const buildTimestamp: string = require('../../../buildTimestamp.json');


@Injectable({
  providedIn: 'root'
})
export class SiteIconsService {
  #scheme: Map<string, object[]> = new Map()
    .set('a_icon', [{ rel: 'apple-touch-icon' }, { href: 'apple-touch-icon.png' }])
    .set('a_icon152', [{ rel: 'apple-touch-icon' }, { href: 'apple-touch-icon-152x152.png' }, { sizes: '152x152' }])
    .set('a_icon120', [{ rel: 'apple-touch-icon' }, { href: 'apple-touch-icon-120x120.png' }, { sizes: '120x120' }])
    .set('a_icon_pre', [{ rel: 'apple-touch-icon-precomposed' }, { href: 'apple-touch-icon-precomposed.png' }])
    .set('a_icon_pre152', [{ rel: 'apple-touch-icon-precomposed' }, { href: 'apple-touch-icon-152x152-precomposed.png' }, { sizes: '152x152' }])
    .set('a_icon_pre120', [{ rel: 'apple-touch-icon-precomposed' }, { href: 'apple-touch-icon-120x120-precomposed.png' }, { sizes: '120x120' }])
    .set('preconnect', [{ rel: 'preconnect' }, { href: `${environment.resourceBase}` }]);

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private pd: PlatformDetectorService,
  ) {}

  init() {
    if (this.pd.isBrowser) {
      return;
    }
    this.#scheme.forEach((v, k) => this.createLink(k, v));
  }

  /**
   * Append or update link TAG
   *
   * @param id is used as unique identifier.
   * @param params is a map with link attributes.
   */
  private createLink(id: string, params: object[]) {
    let link: HTMLLinkElement | null = this.dom.querySelector(`link#${id}`);

    if (!link) {
      link = this.dom.createElement('link');
      this.dom.head.appendChild(link);

      params.forEach(obj => {
        for (const [key, value] of Object.entries(obj)) {
          if(!link) return;
          if(link.rel === 'preconnect') link.setAttribute(key, value)
          else link.setAttribute(key, key === 'href' ? this.resolveIconPath(value) : value);
        }
      });
    }


  }

  private resolveIconPath(icon: string): string {
    return `${environment.protocol}${environment.host}/assets/images/browser-icons/${icon}?v=${buildTimestamp}`;
  }
}
