import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { Location } from '@angular/common';
import {Routing} from "./app/constants/Routing";

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

Location.stripTrailingSlash = function stripTrailingSlashNew(url: string): string {
  const homePageRegex = new RegExp(Routing.HOME_URL_MATCHER, 'gmi');
  const homeUrlMatch = homePageRegex.exec(url);

  if (homeUrlMatch) {
    return url.endsWith('/') ? url : url + '/';
  }
  return url.replace(/\/$/, '');
};
