import {HttpInterceptorFn} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {inject} from "@angular/core";
import {LocaleService} from "../modules/shared/services/locale.service";


export const langToApiReqInterceptor: HttpInterceptorFn = (req, next) => {

  let locale = inject(LocaleService).getLocale();

  if (req.url.startsWith(environment.apiBase) && req.method === 'GET' && !req.url.includes('lang=')) {
    const cloned = req.clone({
      params: req.params.set('lang', locale)
    });

    return next(cloned);
  }

  return next(req);
}
