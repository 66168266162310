import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LogoComponent} from "../../ui/elements/logo/logo.component";
import {MenuButtonComponent} from "../../ui/buttons/menu-button/menu-button.component";
import {BurgerMenuComponent} from "../burger-menu/burger-menu.component";
import {MenuService} from "../../services/menu.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, LogoComponent, MenuButtonComponent, BurgerMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(public menuService: MenuService) {}

}
