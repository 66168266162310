<button type="button" class="menu-button"
        (click)="toggleMenu()"
        aria-label="menu-button">
  <div class="burger-icon">
    <span [ngClass]="{'animate-first': isOpened}"></span>
    <span [ngClass]="{'animate-third': isOpened}"></span>
  </div>
  <p [ngClass]="{'hide': isOpened}">меню</p>
</button>

