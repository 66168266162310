import {ChangeDetectionStrategy, Component, HostBinding, Inject} from '@angular/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import {ShareButtonComponent} from "../share-button/share-button.component";
import {
  BehaviorSubject,
  delay,
  distinctUntilChanged,
  fromEvent,
  interval,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
  throttle
} from "rxjs";
import {PlatformDetectorService} from "../../../services/platform-detector.service";
import {animate} from "@angular/animations";

@Component({
  selector: 'app-scroll-to-top',
  standalone: true,
  imports: [CommonModule, ShareButtonComponent],
  templateUrl: './scroll-to-top.component.html',
  styleUrl: './scroll-to-top.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent {

  private SHOW_DISTANCE = 2000;
  private ANIMATION_DURATION = 300;
  private _show$ = new BehaviorSubject(false);
  private _showAnimation$ = new BehaviorSubject(false);
  private destroy$ = new Subject<void>();
  show$ = this._show$.asObservable();
  showAnimation$ = this._showAnimation$.asObservable();

  @HostBinding('style.--animation-duration') animationDuration = this.ANIMATION_DURATION / 1000 + 's';

  constructor(@Inject(DOCUMENT) private document: Document, private pd: PlatformDetectorService) {
    this.pd.isBrowser && fromEvent(this.document.defaultView as Window, 'scroll')
      .pipe(
        takeUntil(this.destroy$),
        throttle(() => interval(100)),
        map(() => {
          return (this.document.defaultView as Window).scrollY >= this.SHOW_DISTANCE;
        }),
        switchMap(status => {
          this._showAnimation$.next(status);
          return status
            ? of(status)
            : of(status)
              .pipe(
                delay(this.ANIMATION_DURATION),
              );
        })
      )
      .pipe(distinctUntilChanged())
      .subscribe(status => this._show$.next(status));
  }

  scrollToTop() {
    (this.document.defaultView as Window).scroll({ top: 0, behavior: 'smooth' });
  }

  protected readonly animate = animate;
}
