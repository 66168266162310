import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-menu-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {
  @Input() isOpened = false;
  @Output() menuButtonClick = new EventEmitter<void>();

  toggleMenu() {
    this.isOpened = !this.isOpened;
    this.menuButtonClick.emit();
  }
}




