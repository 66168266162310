import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LangSwitcherComponent} from "../../ui/buttons/lang-switcher/lang-switcher.component";
import {SocialsBlockComponent} from "../footer/blocks/socials-block/socials-block.component";
import { MenuService } from '../../services/menu.service';
import { DisableScrollDirective } from '../../directives/disable-scroll.directive';
import {PlatformDetectorService} from "../../services/platform-detector.service";

@Component({
  selector: 'app-burger-menu',
  standalone: true,
  imports: [CommonModule, LangSwitcherComponent, SocialsBlockComponent, DisableScrollDirective],
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerMenuComponent {

  @Input() showMenu = true;
  menu$ = this.menuService.getMenu();
  menuStatus$ = this.menuService.status$;

  constructor(public menuService: MenuService, public pd: PlatformDetectorService) { }

  getTarget(isTargetBlank: boolean): string {
    return isTargetBlank ? '_blank' : '_self';
  }
}
