import { Injectable } from '@angular/core';
import { SiteIconsService } from '../site-icons.service';
import { GtmService } from './gtm.service';
import { OntheioService } from './ontheio.service';

@Injectable({
  providedIn: 'root'
})
export class MetricsServiceService {

  constructor(private gtm: GtmService,
              private siteIconsService: SiteIconsService,
              private ontheioService: OntheioService
              ) { }

  init() {
    this.gtm.init();
    this.ontheioService.init();
    this.siteIconsService.init();
  }
}
