import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {LocaleService} from "./modules/shared/services/locale.service";
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {langToApiReqInterceptor} from "./interceptors/lang-to-api-req.interceptor";
import {
  cacheEvictionHeaderToApiReqInterceptor,
} from "./interceptors/cache-eviction-header-to-api-req.interceptor";


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptors([
      langToApiReqInterceptor,
      cacheEvictionHeaderToApiReqInterceptor,
    ])),
    {
      provide: 'LOCALE',
      useFactory: (localeService: LocaleService) => {
        return localeService.getLocale();
      },
      deps: [LocaleService],
    }
  ]
};
