import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {MainLayoutComponent} from './modules/layouts/main-layout/main-layout.component';
import {Routing} from './constants/Routing';
import {
  authorUrlMatcher, checkStaticPagePath, sitemapUrlMatcher, staticUrlMatcher,
} from './modules/shared/utils/routing.utils';
import {ErrorComponent} from "./modules/pages/error/error.component";
import {NgModule} from "@angular/core";

export const routes: Routes = [
  /**
   * Home Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    pathMatch: 'full',
    matcher: (url: UrlSegment[]) => staticUrlMatcher(url, Routing.HOME_URL_MATCHER),
    data: {mainPage: true},
    loadChildren: () => import('../app/modules/pages/home/home.module').then(m => m.HomeModule)
  },
  /**
   * Tag Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher(url: UrlSegment[]) {
      try {
        if (url.length >= 3 && url[1]?.path === 'tags' && url[2]?.path.match(Routing.ID_TRANSLITERATION_MATCHER)) {
          return {
            consumed: url,
            posParams: {
              url: url[2],
            }
          };
        }
      } catch (e) {
        logError('Router ERROR (Tag URL matcher)  for url: ' + url + ' ||>> ' + e);
      }
      return null;
    },
    loadChildren: () => import('../app/modules/pages/tag/tag.module').then(m => m.TagModule)
  },
  /**
   * News Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher(url: UrlSegment[]) {
      try {
        if (url.length === 3 && url[1].path === 'news'&& url[2]?.path.match(Routing.ID_TRANSLITERATION_MATCHER)){
          return {
            consumed: url,
            posParams: {
              url: url[2],
            }
          };
        }
      } catch (e) {
        logError('Router ERROR (Single News URL matcher) for url: ' + url + ' ||>> ' + e);
      }
      return null;
    },
    loadChildren: () => import('../app/modules/pages/news/news.module').then(m => m.NewsModule)

  },
  /**
   * Author Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher: (url: UrlSegment[]) => authorUrlMatcher(url, Routing.AUTHOR_URL_MATCHER),
    loadChildren: () => import('../app/modules/pages/author/author.module').then(m => m.AuthorModule)
  },

  /**
   * Editors Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher: (url: UrlSegment[]) => staticUrlMatcher(url, Routing.EDITORS_URL_MATCHER),
    loadChildren: () => import('../app/modules/pages/editors/editors.module').then(m => m.EditorsModule)
  },
  /**
   * Contacts Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher: (url: UrlSegment[]) => staticUrlMatcher(url, Routing.CONTACTS_URL_MATCHER),
    loadChildren: () => import('./modules/pages/contacts/contacts.module').then(m => m.ContactsModule)
  },

  /**
   * Static Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher(url: UrlSegment[]) {
      try {
        if (url.length <= 2 && url[ 1 ]?.path && checkStaticPagePath(url[ 1 ]?.path)) {
          return {
            consumed: url,
            posParams: {
              path: url[1]
            }
          };
        }
      } catch (e) {
        logError('Router ERROR (Static page URL matcher)  for url: ' + url + ' ||>> ' + e);
      } return null;
    },
    loadChildren: () => import('./modules/pages/static/static.module').then(m => m.StaticModule)
  },
  /**
   * Site Map Page URL matcher.
   */
  {
    component: MainLayoutComponent,
    matcher: (url: UrlSegment[]) => sitemapUrlMatcher(url, Routing.SITEMAP_URL_MATCHER),
    loadChildren: () => import('./modules/pages/archive-sitemap-page/archive-sitemap-page.module').then(m => m.ArchiveSitemapPageModule)
  },

  {path: '**', component: ErrorComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

function logError(err: string) {
  console.error(`${(new Date()).toISOString()} ERROR [app-routing.ts:0] ${err}`);
}
