import { UrlSegment } from '@angular/router';
import { Constants } from '../../../constants/Constants';
import {Routing} from "../../../constants/Routing";

export function subFolderUrlMatcher(url: UrlSegment[], pattern: string) {
  const matchedUrl = checkUrlMatch(url, pattern);

  if (matchedUrl) {
    return {
      consumed: url[0].path === Constants.LOCALE_RU ? url.slice(0, 2) : url.slice(0, 1),
    };
  }
  return null;
}

export function entityWithIdUrlMatcher(url: UrlSegment[], pattern: string) {
  const matchedUrl = checkUrlMatch(url, pattern);

  if (url.length < 2 && matchedUrl) {
    return {
      consumed: url,
      posParams: {
        ['id']: new UrlSegment(matchedUrl[2], {}),
      }
    };
  }
  return null;
}

export function authorUrlMatcher(url: UrlSegment[], pattern: string) {
  const matchedUrl = checkUrlMatch(url, pattern);

  if (matchedUrl) {
    return {
      consumed: url,
      posParams: {
        url: url[2],
      }
    };
  }
  return null;


  // if (url.length === 3 && url[1].path === 'authors' && url[2].path.match(pattern)) {
  //   return {
  //     consumed: url,
  //     posParams: {
  //       url: url[2]
  //     }
  //   };
  // }
  // return null;
}


export function staticUrlMatcher(url: UrlSegment[], pattern: string) {
  const matchedUrl = checkUrlMatch(url, pattern);

  if (matchedUrl) {
    return {
      consumed: url,
      posParams: {
        staticPageKey: prepareStaticPageKey(url),
      }
    };
  }
  return null;
}

export function checkStaticPagePath(path: string): boolean {
  for (const item of Constants.staticRoutes) {
    if (item.route === path) {
      return true;
    }
  }
  return false;
}

export function sitemapUrlMatcher(url: UrlSegment[], pattern: string) {
  const matchedUrl = checkUrlMatch(url, pattern);
  const dateRegex = new RegExp(Routing.SITEMAP_DATE_MATCHER, 'gmi');
  let segment: UrlSegment | undefined;

  if(url[1] && url[1].path) {
    let execResult: string | undefined;

    if((url[0].path === Constants.LOCALE_RU || url[0].path === Constants.DEFAULT_LOCALE) && url[2]) {
      execResult = dateRegex.exec(url[2].path)?.[1];
    }

    segment = execResult ? new UrlSegment(execResult, {}) : new UrlSegment('', {});
  }

  if (matchedUrl) {
    return {
      consumed: url,
      posParams: {
        ...(segment ? { date: segment } : {})
      }
    };
  }
  return null;
}

const prepareFullUrl = (url: UrlSegment[]) => {
  return url.map((urlSegment: UrlSegment) => urlSegment.path).join('/');
};

const checkUrlMatch = (url: UrlSegment[], pattern: string): RegExpExecArray | null => {
  const fullUrl = prepareFullUrl(url);
  const regex = new RegExp(pattern, 'gmi');
  return regex.exec(fullUrl);
};

const prepareStaticPageKey = (url: UrlSegment[]): UrlSegment  => {
  return url[0]?.path === Constants.LOCALE_RU
    ? new UrlSegment(url[1]?.toString().replaceAll('-', '_').toUpperCase(), {})
    : new UrlSegment(url[0]?.toString().replaceAll('-', '_').toUpperCase(), {});
};
