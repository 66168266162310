import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {map, Observable} from "rxjs";
import {Locale} from "../../../../../translations/models.translations";
import {Constants} from '../../../../../constants/Constants';
import {PageData} from '../../../models/page-data.type';
import {PageDataService} from "../../../services/page-data.service";

@Component({
  selector: 'app-lang-switcher',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangSwitcherComponent implements OnInit{

  url$: Observable<LangSwitcherUrl> | null = null;
  isUkLocale = this.locale === Constants.DEFAULT_LOCALE;

  constructor(@Inject('LOCALE') private locale: Locale,
              private pageDataService: PageDataService
  ) {}

  ngOnInit(): void {
    this.url$ = this.pageDataService.pageData$.pipe(
      map((pageData: PageData | null) => {

        let linkUa = '/';
        let linkRu = '/ru/';

        if (pageData?.alternateUa && pageData?.alternateRu) {
          linkUa = pageData.alternateUa;
          linkRu = pageData.alternateRu;
        }
        return {
          urlUk: linkUa,
          urlRu: linkRu
        };
      })
    )
  }
}

export interface LangSwitcherUrl {
  urlUk: string;
  urlRu: string;
}
