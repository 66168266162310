import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlatformDetectorService} from '../../shared/services/platform-detector.service';
import {RESPONSE} from "../../../token";

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements OnInit {

  response = inject(RESPONSE, {optional: true});
  constructor(
    private pd: PlatformDetectorService,
  ) {}

  ngOnInit(): void {
    if (this.pd.isServer) {
       this.response.status(404);
    }
  }
}
