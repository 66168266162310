import {inject} from '@angular/core';
import {HttpInterceptorFn} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PlatformDetectorService } from '../modules/shared/services/platform-detector.service';
import { Constants } from '../constants/Constants';
import {REQUEST} from "../token";



/*@Injectable()
export class CacheEvictionHeaderToApiReqInterceptor implements HttpInterceptor {

  constructor(
    private pd: PlatformDetectorService,
    @Optional() @Inject(REQUEST) protected request: Request_2
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let hasEvictionHeader = false;

    if (this.pd.isServer) {
      hasEvictionHeader = !!this.request.headers[Constants.CACHE_FORCE_EVICT_HEADER] || !!this.request.headers[Constants.CACHE_FORCE_EVICT_HEADER_NGINX];
    }

    if (hasEvictionHeader && req.url.startsWith(environment.apiBase) && req.method === 'GET') {
      const cloned = req.clone({
        headers: req.headers
          .set(Constants.CACHE_FORCE_EVICT_HEADER, 'true')
          .set(Constants.CACHE_FORCE_EVICT_HEADER_NGINX, 'true'),
      });

      return next.handle(cloned);
    }

    return next.handle(req);
  }
}*/


export const cacheEvictionHeaderToApiReqInterceptor: HttpInterceptorFn = (req, next) => {
  const pd = inject(PlatformDetectorService)

  const request = inject(REQUEST, {optional: true});
  let hasEvictionHeader = false;

  if (pd.isServer && request) {
    hasEvictionHeader = !!request.headers[Constants.CACHE_FORCE_EVICT_HEADER] || !!request.headers[Constants.CACHE_FORCE_EVICT_HEADER_NGINX];
  }



  if (hasEvictionHeader && req.url.startsWith(environment.apiBase) && req.method === 'GET') {
    const cloned = req.clone({
      headers: req.headers
        .set(Constants.CACHE_FORCE_EVICT_HEADER, 'true')
        .set(Constants.CACHE_FORCE_EVICT_HEADER_NGINX, 'true')
        .set(Constants.CACHE_FORCE_EVICT, 'true'),
    });

    return next(cloned);
  }

  return next(req);
}

