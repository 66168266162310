<header class="header-container">
  <app-logo class="logo" />
  <div class="logo-slasher"></div>
  <div class="slasher-bottom"></div>
  <app-menu-button [isOpened]="menuService.status$.value"
                    (menuButtonClick)="menuService.setStatus(!menuService.status$.value)" />

  <app-burger-menu [showMenu]="menuService.status$.value" />

</header>
