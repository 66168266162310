import { Injectable } from '@angular/core';
import { Locale } from '../../../translations/models.translations';
import { UrlService } from "./url.service";
import {PlatformDetectorService} from "./platform-detector.service";


@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(private urlService: UrlService) {
  }

  getLocale() {
    const url = this.urlService.getCurrentUrl();
    return url.includes(`/${Locale.RU}/`) || url.endsWith(`/${Locale.RU}`) ? Locale.RU : Locale.UK;
  }
}
